@font-face {
  font-family: 'cherry-monospace-light';
  src: local('cherry-monospace-light'), url(../../assets/fonts/CherryMonospace-Light.ttf) format('woff');
}

@font-face {
  font-family: 'roboto-light';
  src: local('roboto-light'), url(../../assets/fonts/Roboto-Light.ttf) format('woff');
}

html {
  scroll-behavior: smooth;
}

.mt-2 {
  margin-top: 2rem;
}

.p-10 {
  padding: 10px;
}

.about-me {
  position: absolute;
  bottom: 0;
}

.about-me-text {
  color: white;
  cursor: pointer;
  font-family: cherry-monospace-light;
}

.home-panel {
  height: 100vh;
  background-color: #1a1a1a;
}

.home-panel .container{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.experiences {
  color: #fff;
  background-color: #191919;
  padding-top: 100px;
  padding-bottom: 100px;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.description, .projects {
  background-color: #f6f4f2;
  color: #000;
  padding-top: 100px;
  padding-bottom: 100px;
}

.experiences h4{
  font-family: roboto-light;
  font-weight: bold;
}

.experiences p {
  font-family: roboto-light;
  margin-bottom: 5px;
}

.experience .job-title {
  font-weight: bold;
  font-family: roboto-light;
  margin-bottom: 5px;
  margin-top: 0;
}

.experience .company {
  font-weight: bold;
  font-family: roboto-light;
  font-size: 1.5rem;
  line-height: 1.2;
  margin-bottom: 5px;
  margin-top: 0;
}

.description h3, .experiences h3, .projects h3 {
  padding-bottom: 60px;
  font-family: cherry-monospace-light;
  font-weight: bold;
  margin-bottom: 0.5rem;
  font-size: 1.75rem;
}

.description p {
  font-family: roboto-light;
  font-size: 1em;
  letter-spacing: 1px;
  margin: 0px;
}

.email {
  font-size: 20px;
  /* text-align: center; */
  font-weight: bold;
  cursor: pointer;
  border: 1px solid black;
  border-radius: 5px;
  padding: 5px;
  background-color: white;
  color: black;
}

.social-group {
  position: absolute;
  top: 50%;
}

.social-item {
  padding: 5px;
}

.project-description {
  margin-top: 0;
  margin-bottom: 1rem;
}

.icon {
  width: 25px;
  height: 25px;
  padding: 0px;
}

.space-left {
  margin-left: 10px;
}

.name {
  text-align: center;
  color: white;
  font-family: cherry-monospace-light;
  font-weight: bold;
  font-size: 2.5rem;
  margin-top: 0;
  margin-bottom: 0.5rem;
  line-height: 1.2;
}

.name-subtitle {
  text-align: center;
  color: white;
  font-size: 1rem;
  margin-top: 0;
  font-family: cherry-monospace-light;
}


#toasty {
  width: 35vh;
  position: absolute;
  left: -24%;
  top: -24%; 
}

@media only screen and (max-width: 1024px) {
  #toasty {
    display: none;
  }

  .name {
    font-size: 1.6rem;
  }

  .name-subtitle {
    font-size: 0.7rem;
  }
}

#toasty.show {
  -webkit-animation: fadein 3s, fadeout 3s 5s;
  animation: fadein 3s, fadeout 3s 5s;
}

/* Animations to fade the toasty in and out */
@-webkit-keyframes fadein {
  from {left: -24%; top: -24%; opacity: 0;} 
  to {left: 0px; top: 0px; opacity: 1;}
}

@keyframes fadein {
  from {left: -24%; top: -24%; opacity: 0;}
  to {left: 0px; top: 0px; opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {left: 0px; top: 0px; opacity: 1;} 
  to {left: -24%; top: -24%; opacity: 0;}
}

@keyframes fadeout {
  from {left: 0px; top: 0px; opacity: 1;}
  to {left: -24%; top: -24%; opacity: 0;}
}
